import React from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';
import { ColorType } from './interfaces';

const StyledDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.offWhite};
`;

interface IDivider {
  color?: ColorType;
}

const Divider: React.FC<IDivider> = ({
  color = 'pageBackground',
  ...props
}) => (
  <StyledDivider
    style={{
      backgroundColor: theme.colors[color] ? theme.colors[color] : color
    }}
    {...props}
  />
);

export default Divider;
