import { useContext } from 'react';
import { SnackbarContext } from '../context/SnackbarContext';

const useSnackbar = () => {
  const { handleMessage } = useContext(SnackbarContext);

  return { handleMessage };
};

export default useSnackbar;
