import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { SNACKBAR_DURATION } from '../../constants';
import Typography from './typography';

interface ISnackbar {
  open: boolean;
  message: string;
  duration?: number;
}

const height = '120px';

const fadein = keyframes`
  from { top: -${height}; }
  to { top: 0; }
`;

const fadeout = keyframes`
  from { top: 0; }
  to { top: -${height}; }
`;

const StyledContainer = styled.div<ISnackbar>`
  padding: 1.5rem;
  position: fixed;
  top: -${height};
  left: 0;
  right: 0;
  height: 70px;
  z-index: 99999;
  background-color: ${({ theme }) => theme.colors.secondary};
  display: none;
  align-items: center;
  border-radius: 0 0 3px 3px;
  ${({ open, duration }) =>
    open &&
    css`
      display: flex;
      animation: ${fadein} 1s forwards,
        ${fadeout} 1s ${(duration ?? SNACKBAR_DURATION) - 1000}ms forwards;
    `};
`;

const Snackbar: React.FC<ISnackbar> = (props) => (
  <StyledContainer {...props}>
    <Typography align="center" color="textSecondary" variant="h4">
      {props.message}
    </Typography>
  </StyledContainer>
);

export default Snackbar;
