import React, { useState, useMemo } from 'react';
import * as Styled from './style';
import HamburgerMenu from '../../atoms/hamburgerMenu';
import Menu from '../../molecules/menu';
import { Container } from 'react-grid-system';
import FacebookLogo from '../../../images/icons/facebook_blue.png';
import { facebookUrl } from '../../../constants';
import { INavLinks } from '../../atoms/interfaces';
import useScreenClassHelper from '../../../hooks/useScreenClassHelper';
import theme from '../../../styles/theme';
import Routes from '../../../services/routes';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby-link';
import {
  getPaymentId,
  getPaymentNumber,
  resetPaymentDetails
} from '../../../utils/localStorage';
import Modal from '../../molecules/modal';
import { abortPayment } from '../../../api';
import usePaymentContext from '../../../hooks/usePaymentContext';

interface IHeader {
  image: string;
  navLinks: INavLinks[];
}

const Header: React.FC<IHeader> = ({ image, navLinks }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();
  const { IS_MOBILE } = useScreenClassHelper();
  const [isPaymentInProgress, setPaymentInProgress] = useState(false);
  const [currentRoute, setCurrentRoute] = useState<string>();
  const [isAbortLoading, setIsAbortLoading] = useState(false);

  const socialIcons = [{ to: facebookUrl, icon: FacebookLogo }];

  const { resetData } = usePaymentContext();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const burgerColor = useMemo(() => {
    if (IS_MOBILE) {
      return theme.colors.primary;
    }
    if (
      location?.pathname.includes(Routes.DASHBOARD) &&
      location.pathname !== Routes.ORDER_HOME_PAYMENT
    ) {
      return theme.colors.primary;
    }
    return theme.colors.offWhite;
  }, [location?.pathname]);

  const handleMenuItemClicked = (to: string) => {
    const paymentId = getPaymentId();
    if (
      paymentId === null ||
      (location?.pathname !== Routes.ORDER &&
        location?.pathname !== Routes.ORDER_HOME_PAYMENT)
    ) {
      navigate(to);
    } else {
      setCurrentRoute(to);
      setPaymentInProgress(true);
    }
  };

  const handleLogoClick = () => {
    const paymentId = getPaymentId();
    if (
      paymentId === null ||
      (location?.pathname !== Routes.ORDER &&
        location?.pathname !== Routes.ORDER_HOME_PAYMENT)
    ) {
      navigate(Routes.LANDING_PAGE);
    } else {
      setPaymentInProgress(true);
    }
  };

  const handleClosePageConfirm = async () => {
    const paymentNumber = getPaymentNumber();
    if (paymentNumber) {
      setIsAbortLoading(true);
      await abortPayment(paymentNumber);
      resetData();
      setIsAbortLoading(false);
    }
    resetPaymentDetails();
    setPaymentInProgress(false);
    if (currentRoute) {
      navigate(currentRoute);
    } else {
      navigate(Routes.LANDING_PAGE);
    }
  };

  return (
    <div>
      <Menu
        open={isMenuOpen}
        navLinks={navLinks}
        socialIcons={socialIcons}
        onClose={toggleMenu}
        onMenuItemClicked={handleMenuItemClicked}
      />
      <Styled.Header isMobile={IS_MOBILE}>
        <Styled.Shadow />
        <Container fluid>
          <Styled.HeaderInner>
            <Styled.ContainerLogo onClick={handleLogoClick}>
              <Styled.ImageLogo src={image} alt="logga" />
            </Styled.ContainerLogo>
            {!isMenuOpen && (
              <Styled.Menu onClick={toggleMenu}>
                <HamburgerMenu color={burgerColor} />
              </Styled.Menu>
            )}
          </Styled.HeaderInner>
        </Container>
      </Styled.Header>
      {isPaymentInProgress && (
        <Modal
          isLoading={isAbortLoading}
          title="Är du säker på att du vill avsluta?"
          primaryButtonProps={{
            title: 'Ja',
            onClick: handleClosePageConfirm
          }}
          secondaryButtonProps={{
            title: 'Nej',
            onClick: () => setPaymentInProgress(false)
          }}
        />
      )}
    </div>
  );
};

export default Header;
