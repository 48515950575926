import React, { useEffect, useState } from 'react';
import { FirebaseContext } from './context';
import firebase from 'gatsby-plugin-firebase';
import { fetchContextData } from '../../api';
import { PAYMENT_TYPE } from '../../utils/localStorage';

export { FirebaseContext };

export interface IUserData {
  ssn?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface IAddressData {
  streetAddress?: string;
  city?: string;
  zipCode?: string;
}

export interface IStorageData {
  recurrenceToken: string;
  user: string;
  isFree: boolean;
}

export interface IReceiptData {
  amount: number;
  created: string;
  currency: string;
  id: string;
  number: number;
  user: string;
  type: PAYMENT_TYPE;
}

export interface IData {
  user?: IUserData;
  address?: IAddressData;
  receipts?: IReceiptData[];
  storage?: IStorageData[];
}

export interface IFirebaseData extends IData {
  storageAmount: number;
  productAmount: number;
}

export interface ProviderValue extends IFirebaseData {
  fetchData: () => void;
  clearData: () => void;
}

const initState = {
  user: undefined,
  address: undefined,
  receipts: [],
  storage: [],
  storageAmount: 0,
  productAmount: 0
};

const FirebaseProvider: React.FC = ({ children }) => {
  const [userData, setUserData] = useState<IFirebaseData>(initState);

  const fetchData = async () => {
    const uid = firebase.auth().currentUser?.uid;
    if (uid) {
      const { user, address, receipts, storage }: IData =
        await fetchContextData(uid);

      setUserData({
        user,
        address,
        receipts,
        storage,
        storageAmount: storage?.length ?? 0,
        productAmount: storage?.filter((product) => !product.isFree).length ?? 0
      });
    }
  };

  const clearData = () => {
    setUserData(initState);
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((userData) => {
      if (userData) {
        fetchData();
      }
    });

    return unsubscribe;
  }, []);

  return (
    <FirebaseContext.Provider value={{ ...userData, fetchData, clearData }}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
