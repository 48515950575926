import styled from 'styled-components';

export const ImageLogo = styled.img`
  object-fit: contain;
  width: 190px;
`;

export const PartnerLogo = styled.img`
  margin: auto;
  display: block;
`;
