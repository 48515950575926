import React, { ReactElement } from 'react';
import styled from 'styled-components';
import Typography from '../atoms/typography';
import Button from '../atoms/button';
import Spacer from '../atoms/spacer';
import { Col, Row } from 'react-grid-system';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';

interface IModalButton {
  title: string;
  onClick: () => void;
}

export interface IModal {
  title?: string | ReactElement;
  description?: string | ReactElement;
  primaryButtonProps?: IModalButton;
  secondaryButtonProps?: IModalButton;
  isLoading?: boolean;
}

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 899;
  background-color: rgba(87, 87, 87, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  min-height: 300px;
  max-height: 100vh;
  overflow: auto;
  width: 80%;
  max-width: 834px;
  border-radius: 10px;
  padding: 3rem 2rem;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.modalBackground};
`;

const PrimaryButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary};
`;

const Modal: React.FC<IModal> = ({
  title,
  description,
  primaryButtonProps,
  secondaryButtonProps,
  isLoading = false,
  ...props
}) => {
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <ModalWrapper {...props}>
      <ModalContent>
        {!!title && (
          <Typography
            align="center"
            color="primary"
            variant={IS_MOBILE ? 'h3' : 'h2'}
          >
            {title}
          </Typography>
        )}
        {description && (
          <>
            <Spacer />
            <Typography variant={IS_MOBILE ? 'h4' : 'h3'}>
              {description}
            </Typography>
          </>
        )}
        {(primaryButtonProps || secondaryButtonProps) && (
          <>
            <Spacer spacing={IS_MOBILE ? 2 : 3} />
            <Row>
              {primaryButtonProps && (
                <Col xs={6}>
                  <PrimaryButton
                    isLoading={isLoading}
                    onClick={primaryButtonProps.onClick}
                  >
                    {primaryButtonProps.title}
                  </PrimaryButton>
                </Col>
              )}
              {secondaryButtonProps && (
                <Col xs={6}>
                  <Button onClick={secondaryButtonProps.onClick}>
                    {secondaryButtonProps.title}
                  </Button>
                </Col>
              )}
            </Row>
          </>
        )}
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
