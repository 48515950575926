import { useEffect, useState } from 'react';
import { initPayment } from '../api';
import { SEAMLESS_VIEW_ID } from '../constants';
import isBrowser from '../utils/isBrowser';
import {
  getPanboxOrderAmount,
  savePanboxOrderAmount,
  savePaymentId,
  savePaymentNumber
} from '../utils/localStorage';
import useFirebaseContext from './useFirebaseContext';
import useSnackbar from './useSnackbar';

interface IInitialisePayment {
  amount: number;
  numberOfPanBoxes?: number;
}

const usePayment = () => {
  const { user } = useFirebaseContext();
  const { handleMessage } = useSnackbar();
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  useEffect(() => {
    return () => {
      window.payex = undefined;
    };
  }, []);

  const initiatePayment = async ({
    amount,
    numberOfPanBoxes = 0
  }: IInitialisePayment): Promise<boolean> => {
    setIsPaymentLoading(true);
    if (getPanboxOrderAmount() === null) {
      savePanboxOrderAmount(numberOfPanBoxes);
    }

    const res = await initPayment({
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
      ssn: user?.ssn ?? '',
      amount
    });
    if (res.statusCode === 400) {
      setIsPaymentLoading(false);
      return false;
    }

    savePaymentId(res.paymentId);
    savePaymentNumber(res.paymentNumber);

    const script = document.createElement('script');
    script.src = res.hrefRedirectAuthorization;
    script.async = true;

    const paymentContainer = document.getElementById(SEAMLESS_VIEW_ID);

    if (!paymentContainer) {
      handleMessage('Betalning kan inte initieras. Vänligen försök igen');
      return false;
    }
    paymentContainer.appendChild(script);
    return true;
  };

  const startPayment = async () => {
    let interval: NodeJS.Timeout;
    if (isBrowser()) {
      if (!window.payex) {
        interval = setInterval(() => {
          if (window.payex) {
            setIsPaymentLoading(false);
            window.payex.hostedView
              .creditCard({
                container: SEAMLESS_VIEW_ID
              })
              .open();
            clearInterval(interval);
          }
        }, 1000);
      } else {
        setIsPaymentLoading(false);
        window.payex.hostedView
          .creditCard({
            container: SEAMLESS_VIEW_ID
          })
          .open();
      }
    }
  };

  return {
    initiatePayment,
    startPayment,
    isPaymentLoading
  };
};

export default usePayment;
