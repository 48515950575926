import { PromoCodeRes } from '../models/payment';

enum Prices {
  SUBSCRIPTION = 19,
  SHIPPING = 79,
  PRODUCT = 349
}

export default Prices;

export const formatPrice = (price: number, showFractions = false): string =>
  new Intl.NumberFormat('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    maximumFractionDigits: showFractions ? 2 : 0,
    minimumFractionDigits: showFractions ? 2 : 0
  }).format(price);

export const getOrderHomeTotalPrice = ({
  orderAmount,
  storageAmount,
  productAmount,
  isRestock
}: {
  orderAmount: number;
  isRestock: boolean;
  storageAmount: number;
  productAmount: number;
}): number => {
  let productCost = 0;
  let subscriptionCost = 0;
  const freeSlots = storageAmount - productAmount;
  const newSlots = freeSlots - orderAmount;

  if (isRestock) {
    productCost = Prices.PRODUCT * orderAmount;
    if (newSlots > 0) {
      subscriptionCost = Prices.SUBSCRIPTION * newSlots;
    }
  }

  return productCost + subscriptionCost + Prices.SHIPPING * orderAmount;
};

export const getDetailedInfo = (
  orderAmount: number,
  freeSlots: number
): { price: number; title: string } => {
  const newSlots = freeSlots - orderAmount;
  let total = Prices.PRODUCT * orderAmount;

  if (newSlots > 0) {
    total += Prices.SUBSCRIPTION * newSlots;
  }
  return {
    price: total,
    title: `Påfyllnad av panBox (${orderAmount} st ${
      newSlots > 0 ? '+ månadskostnad' : ''
    })`
  };
};

export const getDefaultTotalPrice = ({
  storageAmount,
  productAmount,
  orderAmount,
  promo
}: {
  storageAmount: number;
  productAmount: number;
  orderAmount: number;
  promo?: PromoCodeRes;
}): {
  totalCost: number;
  subscriptionCost: number;
  newSlots: number;
  productCost: number;
  totalSaleCost: number;
} => {
  const freeSlots = storageAmount - productAmount;
  const newSlots = orderAmount - freeSlots;

  const subscriptionCost = newSlots > 0 ? newSlots * Prices.SUBSCRIPTION : 0;
  const productCost = orderAmount * Prices.PRODUCT;
  const totalCost = productCost + subscriptionCost;

  return {
    totalCost,
    subscriptionCost,
    newSlots,
    productCost,
    totalSaleCost: getPriceWithPromo(productCost, promo) + subscriptionCost
  };
};

export const getPriceWithPromo = (
  productCost: number,
  promo?: PromoCodeRes
): number =>
  promo
    ? promo?.type === 'amount'
      ? productCost - promo.value
      : productCost * (1 - promo.value / 100)
    : productCost;
