import firebase from 'gatsby-plugin-firebase';
import {
  IAddressData,
  IData,
  IReceiptData,
  IStorageData,
  IUserData
} from '../context/FirebaseContext';
import { IPaymentInitPost, PromoCodeRes } from '../models/payment';
import { PAYMENT_TYPE, saveSessionId } from '../utils/localStorage';

enum CLOUD_ENDPOINTS {
  INIT_PAYMENT = 'initPayment',
  VERIFY_PAYMENT = 'verifyPayment',
  GENERATE_TOKEN = 'generateToken',
  GRANDID_FEDERATED_LOGIN = 'federatedLogin',
  GRANDID_GET_SESSION = 'getSession',
  ABORT_PAYMENT = 'abortPayment'
}

enum COLLECTIONS {
  RECEIPT = 'receipt',
  ADDRESSES = 'addresses',
  USERS = 'users',
  STORAGE = 'storage',
  PROMO_CODE = 'promoCode'
}

const encode = (data: any) =>
  Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');

export const submitNetlifyForm = async (name: string, values: any) => {
  if (process.env.NODE_ENV !== 'production') {
    return true;
  }
  try {
    const response = await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': name,
        ...values
      })
    });
    return response.ok;
  } catch (e) {
    return false;
  }
};

export const signInWithToken = async (ssn: string) => {
  try {
    const fbInstance = firebase.app().functions('europe-west1');
    if (process.env.NODE_ENV !== 'production') {
      fbInstance.useEmulator('localhost', 5001);
    }

    const generateToken = fbInstance.httpsCallable(
      CLOUD_ENDPOINTS.GENERATE_TOKEN
    );
    const result = await generateToken({ id: ssn });
    if (result.data.status === 400) throw Error;
    await firebase.auth().signInWithCustomToken(result.data.token);
    return true;
  } catch (error) {
    return false;
  }
};

export const submitDetailsForm = async (
  ssn: string,
  userInfo?: IUserData,
  addressInfo?: IAddressData
) => {
  try {
    if (userInfo) {
      const userDoc = firebase
        .firestore()
        .collection(COLLECTIONS.USERS)
        .doc(ssn);
      await userDoc.set(userInfo, { merge: true });
    }
    if (addressInfo) {
      const addressDoc = firebase
        .firestore()
        .collection(COLLECTIONS.ADDRESSES)
        .doc(ssn);
      await addressDoc.set(addressInfo, { merge: true });
    }
    return true;
  } catch (error) {
    return false;
  }
};

export const fetchContextData = async (ssn: string): Promise<IData> => {
  try {
    const addressDocument = await firebase
      .firestore()
      .collection(COLLECTIONS.ADDRESSES)
      .doc(ssn)
      .get();
    const userDocument = await firebase
      .firestore()
      .collection(COLLECTIONS.USERS)
      .doc(ssn)
      .get();

    const storageSnapshot = await firebase
      .firestore()
      .collection(COLLECTIONS.STORAGE)
      .where('user', '==', ssn)
      .get();
    const storage: IStorageData[] = [];
    storageSnapshot.forEach((product) => {
      storage.push(product.data() as IStorageData);
    });

    const receiptsSnapshot = await firebase
      .firestore()
      .collection(COLLECTIONS.RECEIPT)
      .where('user', '==', ssn)
      .orderBy('created', 'desc')
      .get();
    const receipts: IReceiptData[] = [];
    receiptsSnapshot.forEach((doc) => {
      receipts.push(doc.data() as IReceiptData);
    });

    return {
      user: userDocument.data(),
      address: addressDocument.data(),
      receipts,
      storage
    };
  } catch (e) {
    return {
      user: undefined,
      address: undefined,
      receipts: [],
      storage: []
    };
  }
};

export const initPayment = async (
  body: IPaymentInitPost
): Promise<{
  statusCode: number;
  hrefRedirectAuthorization: string;
  hrefUpdatePaymentAbort: string;
  paymentId: string;
  paymentNumber: string;
}> => {
  try {
    const fbInstance = firebase.app().functions('europe-west1');
    if (process.env.NODE_ENV !== 'production') {
      fbInstance.useEmulator('localhost', 5001);
    }

    const initPayment = fbInstance.httpsCallable(CLOUD_ENDPOINTS.INIT_PAYMENT);
    const res = await initPayment(body);

    if (res.data.statusCode === 400) throw Error;

    return res.data;
  } catch (error) {
    return {
      statusCode: 400,
      hrefRedirectAuthorization: '',
      hrefUpdatePaymentAbort: '',
      paymentId: '',
      paymentNumber: ''
    };
  }
};

export const verifyPayment = async ({
  paymentId,
  ssn,
  orderAmount,
  type
}: {
  paymentId: string;
  ssn: string;
  orderAmount: number | null;
  type: PAYMENT_TYPE;
}): Promise<{
  statusCode: number;
}> => {
  try {
    const fbInstance = firebase.app().functions('europe-west1');
    if (process.env.NODE_ENV !== 'production') {
      fbInstance.useEmulator('localhost', 5001);
    }

    const verifyPayment = fbInstance.httpsCallable(
      CLOUD_ENDPOINTS.VERIFY_PAYMENT
    );

    const result = await verifyPayment({
      paymentId,
      ssn,
      orderAmount: orderAmount ?? 1,
      type
    });

    return result.data;
  } catch (error) {
    return {
      statusCode: 400
    };
  }
};

export const endSubscription = async (
  ssn: string,
  onlyFreeSlots = false
): Promise<{ statusCode: number }> => {
  try {
    let collRef;
    if (onlyFreeSlots) {
      collRef = firebase
        .firestore()
        .collection(COLLECTIONS.STORAGE)
        .where('user', '==', ssn)
        .where('isFree', '==', true);
    } else {
      collRef = firebase
        .firestore()
        .collection(COLLECTIONS.STORAGE)
        .where('user', '==', ssn);
    }

    const snapshot = await collRef.get();

    if (!snapshot.empty) {
      snapshot.forEach((doc) => {
        doc.ref.delete();
      });
    }
    return { statusCode: 200 };
  } catch (error) {
    return { statusCode: 400 };
  }
};

interface IUserAttributes {
  personalNumber: string;
  name: string;
  givenName: string;
  surname: string;
  ipAddress: string;
  notBefore: string;
  notAfter: string;
  signature: string;
  ocspResponse: string;
}

export interface IGetSessionResponse {
  sessionId: string;
  username: string;
  userAttributes: IUserAttributes;
}

export const identifyWithBankID = async (ssn: string): Promise<boolean> => {
  try {
    const fbInstance = firebase.app().functions('europe-west1');
    if (process.env.NODE_ENV !== 'production') {
      fbInstance.useEmulator('localhost', 5001);
    }

    const grandidFederatedLogin = fbInstance.httpsCallable(
      CLOUD_ENDPOINTS.GRANDID_FEDERATED_LOGIN
    );

    const federatedData = await grandidFederatedLogin({ ssn });
    if (federatedData.data.sessionId) {
      saveSessionId(federatedData.data.sessionId);
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
};

export const getBankIDData = async (
  sessionId: string
): Promise<IGetSessionResponse | undefined> => {
  try {
    const fbInstance = firebase.app().functions('europe-west1');
    if (process.env.NODE_ENV !== 'production') {
      fbInstance.useEmulator('localhost', 5001);
    }

    const grandidGetSession = fbInstance.httpsCallable(
      CLOUD_ENDPOINTS.GRANDID_GET_SESSION
    );

    const sessionData = await grandidGetSession({
      sessionId: sessionId
    });
    return sessionData.data;
  } catch (error) {
    return;
  }
};

export const abortPayment = async (
  paymentNumber: string
): Promise<{ statusCode: number }> => {
  const fbInstance = firebase.app().functions('europe-west1');
  if (process.env.NODE_ENV !== 'production') {
    fbInstance.useEmulator('localhost', 5001);
  }

  const abortPayment = fbInstance.httpsCallable(CLOUD_ENDPOINTS.ABORT_PAYMENT);
  const res = await abortPayment({ paymentNumber });

  return {
    statusCode: res.data.statusCode
  };
};

export const updateUserData = async (
  ssn: string,
  data: IUserData
): Promise<boolean> => {
  try {
    await firebase
      .firestore()
      .collection(COLLECTIONS.USERS)
      .doc(ssn)
      .update(data);
    return true;
  } catch (error) {
    return false;
  }
};

export const getPromoCodeValue = async (
  promoCode: string
): Promise<undefined | PromoCodeRes> => {
  const snapshot = await firebase
    .firestore()
    .collection(COLLECTIONS.PROMO_CODE)
    .doc(promoCode.toLowerCase())
    .get();

  if (!snapshot.exists) return;

  return snapshot.data() as PromoCodeRes;
};
