import { createGlobalStyle } from 'styled-components';
import { SEAMLESS_VIEW_ID } from '../constants';

export default createGlobalStyle`

  * {
    font-size: 20px;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    line-height: 1.2;
    outline: none;
    font-family: PT Sans, Kobenhavn, sans-serif
  }
  @media only screen and (max-width: 614px)  {
    * {
      font-size: 15px;
    }
  }
  #${SEAMLESS_VIEW_ID} > iframe {
    min-height: 540px;
  }
`;
