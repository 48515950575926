import { useMemo } from 'react';
import { useScreenClass } from 'react-grid-system';

const mobileSizesArr = ['xs', 'sm'];
const largeDesktopSizesArr = ['xl', 'xxl'];

const useScreenClassHelper = () => {
  const screenClass = useScreenClass();

  return useMemo(
    () => ({
      IS_MOBILE: mobileSizesArr.includes(screenClass),
      IS_LARGE_DESKTOP: largeDesktopSizesArr.includes(screenClass)
    }),
    [screenClass]
  );
};

export default useScreenClassHelper;
