import React from 'react';
import { Container as GridContainer, ContainerProps } from 'react-grid-system';
import styled, { css } from 'styled-components';
import { MAX_PAGE_WIDTH } from '../../services/sizeHelper';
import { ColorType } from './interfaces';

interface IContainer extends ContainerProps {
  backgroundColor?: ColorType;
}

const ContainerInner = styled(GridContainer)`
  max-width: ${MAX_PAGE_WIDTH} !important;
`;

const ContainerWrapper = styled.div<IContainer>`
  ${({ backgroundColor, theme }) =>
    !!backgroundColor &&
    css`
      background-color: ${theme.colors[backgroundColor] ?? backgroundColor};
    `}
`;

const Container: React.FC<IContainer> = ({ backgroundColor, ...props }) => (
  <ContainerWrapper backgroundColor={backgroundColor} fluid>
    <ContainerInner {...props} />
  </ContainerWrapper>
);

export default Container;
