import panBoxIcon from '../images/icons/panbox-icon.png';
import deliveryIcon from '../images/icons/delivery-icon.png';
import paymentIcon from '../images/icons/payment-icon.png';
import userIcon from '../images/icons/user-icon.png';

enum Routes {
  PRODUCTS = '/produkter',
  LANDING_PAGE = '/',
  CONTACT = '/kontakta-oss',
  DASHBOARD = '/mina-sidor',
  MY_DETAILS = '/mina-sidor/mina-uppgifter',
  PAYMENT_METHOD = '/mina-sidor/betalnings-satt',
  ORDER_HOME = '/mina-sidor/bestall-hem',
  ORDER = '/bestall',
  COMPANY = '/foretagsansokan',
  ABOUT = '/om-panbox',
  SIGN_IN = '/logga-in',
  WELCOME = '/valkommen',
  ORDER_HOME_PAYMENT = '/mina-sidor/bestall-hem-betala',
  PAYMENT_COMPLETED = '/klar',
  ERROR = '/fel',
  SUBSCRIPTION = '/mina-sidor/abonnemang',
  PAYMENT_DETAILS = '/mina-sidor/betalningsuppgifter',
  TERMS_AND_CONDITIONS = '/allmanna-villkor',
  COOKIE_POLICY = '/cookie-policy',
  DATA_PROTECTION_POLICY = '/dataskyddspolicy'
}

export const footerLinks = [
  {
    title: 'Allmänna villkor',
    to: Routes.TERMS_AND_CONDITIONS
  },
  {
    title: 'Cookie policy',
    to: Routes.COOKIE_POLICY
  },
  {
    title: 'Dataskyddspolicy',
    to: Routes.DATA_PROTECTION_POLICY
  }
];

export const menuLinks = [
  {
    title: 'Start',
    to: Routes.LANDING_PAGE
  },
  {
    title: 'Produkter',
    to: Routes.PRODUCTS
  },
  {
    title: 'Kontakta oss',
    to: Routes.CONTACT
  },
  {
    title: 'Om panBox',
    to: Routes.ABOUT
  },
  {
    title: 'Boka din panBox',
    to: Routes.ORDER
  },
  {
    title: 'Mina sidor',
    to: Routes.DASHBOARD
  }
];

export const drawerLinks = [
  {
    id: 0,
    icon: panBoxIcon,
    title: 'Min panBox',
    route: Routes.DASHBOARD
  },
  {
    id: 1,
    icon: userIcon,
    title: 'Adressuppgifter',
    route: Routes.MY_DETAILS
  },
  {
    id: 2,
    icon: paymentIcon,
    title: 'Betalsätt',
    route: Routes.PAYMENT_METHOD
  },
  {
    id: 3,
    icon: deliveryIcon,
    title: 'Beställ hem',
    route: Routes.ORDER_HOME
  }
];

export default Routes;
