import React from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import { ColorType, INavLinks } from '../atoms/interfaces';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import styled from 'styled-components';

interface INavigationBar {
  navLinks: INavLinks[];
  color?: ColorType;
}

const StyledLink = styled(Typography)`
  display: block;
  text-align: center;
  :hover {
  -webkit-filter: invert(1);
  filter: invert(1);
  }
`;

const NavigationBar: React.FC<INavigationBar> = ({
  navLinks,
  color = 'offWhite'
}) => (
  <Row>
    {navLinks.map((navLink, index) => (
      <Col xs={12} md={12 / navLinks.length} key={navLink.title}>
        <Visible sm xs>
          <Spacer />
        </Visible>

        <StyledLink
          color={color}
          to={navLink.to}
          href={navLink.href}
          variant={navLink.to ? 'link' : 'external-link'}
          style={
            index !== navLinks.length - 1
              ? { marginRight: '0.5rem' }
              : undefined
          }
        >
          {navLink.title}
        </StyledLink>
      </Col>
    ))}
  </Row>
);

export default NavigationBar;
