import React from 'react';
import ReactDOM from 'react-dom';
import { ScreenClassProvider } from 'react-grid-system';
import { ThemeProvider } from 'styled-components';
import Layout from './src/components/Layout';
import theme from './src/styles/theme';
import SnackbarContextProvider from './src/context/SnackbarContext';
import FirebaseContextProvider from './src/context/FirebaseContext';
import PaymentContextProvider from './src/context/PaymentContext';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import Routes from './src/services/routes';

export const replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
};

export const wrapRootElement = ({ element }) => (
  <ScreenClassProvider>
    <ThemeProvider theme={theme}>
      <FirebaseContextProvider>
        <SnackbarContextProvider>
          <PaymentContextProvider>{element}</PaymentContextProvider>
        </SnackbarContextProvider>
      </FirebaseContextProvider>
    </ThemeProvider>
  </ScreenClassProvider>
);

export const wrapPageElement = ({ element, ...props }) =>
  props.props.uri.includes(Routes.DASHBOARD) ? (
    element
  ) : (
    <Layout {...props}>{element}</Layout>
  );
