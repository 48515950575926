import React, { useEffect } from 'react';
import GlobalStyles from '../styles/global';
import Footer from './organisms/footer';
import Header from './organisms/header';
import Logo from '../images/logo.svg';
import FacebookLogo from '../images/icons/facebook-round.png';
import GoToTopButton from './organisms/scrollToTop';
import { facebookUrl } from '../constants';
import { ISocialImageLink } from './atoms/interfaces';
import mobileLogo from '../images/panbox_logo_green.png';

import useScreenClassHelper from '../hooks/useScreenClassHelper';
import Routes, { footerLinks, menuLinks } from '../services/routes';
import styled from 'styled-components';
import { useLocation } from '@reach/router';
import { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';

const socialImages: ISocialImageLink[] = [
  { href: facebookUrl, src: FacebookLogo }
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContainerContent = styled.div`
  flex: 1;
`;

const unAuthMenuLinks = [
  ...menuLinks.slice(0, menuLinks.length - 1),
  {
    title: 'Logga in',
    to: Routes.SIGN_IN
  }
];

const Layout: React.FC = (props) => {
  const { IS_MOBILE } = useScreenClassHelper();
  const location = useLocation();

  const [navLinks, setNavLinks] = useState(unAuthMenuLinks);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setNavLinks(menuLinks);
      } else {
        setNavLinks(unAuthMenuLinks);
      }
    });
    return () => unsubscribe();
  }, [location?.pathname]);

  return (
    <Container>
      <GoToTopButton />
      <GlobalStyles />
      <Header image={IS_MOBILE ? mobileLogo : Logo} navLinks={navLinks} />
      <ContainerContent>{props.children}</ContainerContent>
      <Footer image={Logo} socialImages={socialImages} navLinks={footerLinks} />
    </Container>
  );
};

export default Layout;
