import React from 'react';
import styled, { css } from 'styled-components';

interface IIcon extends React.ImgHTMLAttributes<HTMLImageElement> {
  width?: number;
}

const IconImage = styled.img`
  width: ${(props) => props.width}px;
  object-fit: contain;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
    `}
`;

const Icon: React.FC<IIcon> = (props) => <IconImage {...props} />;

export default Icon;
