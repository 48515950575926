import React from 'react';
import { ColorType, INavLinks } from '../atoms/interfaces';
import Spacer from '../atoms/spacer';
import Typography from '../atoms/typography';
import Icon from '../atoms/icon';
import CloseIcon from '../../images/icons/close.png';
import ArrowIcon from '../../images/icons/arrow-right-green.png';
import styled, { css, keyframes } from 'styled-components';
import useScreenClassHelper from '../../hooks/useScreenClassHelper';
import menuBg from '../../images/menu-bg.png';

interface IHeaderNavigation {
  navLinks: INavLinks[];
  color?: ColorType;
  onClose: () => void;
  socialIcons: ISocialicons[];
  open: boolean;
  onMenuItemClicked: (to: string) => void;
}

interface ISocialicons {
  to: string;
  icon: string;
}

interface IMobile {
  isMobile: boolean;
}

interface IOpen {
  open: boolean;
}

const Overlay = styled.div<IOpen>`
  ${({ open }) =>
    open
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
          transition: visibility 0.5s;
        `}
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`;

const MenuLinkIcon = styled(Icon)`
  margin-left: 0.5rem;
`;

const MenuLinkItem = styled(Typography)`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  :hover {
    color: ${(props) => props.theme.colors.secondary};
  }
`;

const MenuContentContainer = styled.div<IMobile>`
  position: fixed;
  top: 70px;
  right: 45px;
  z-index: 999;
  flex-direction: column;
  ${(props) =>
    props.isMobile &&
    css`
      top: 55px;
      right: 35px;
    `};
`;

const MenuInner = styled.div`
  flex: 1;
  flex-direction: column;
  text-align: end;
`;

const MenuBackground = styled.img<IMobile>`
  object-fit: contain;
  position: fixed;
  right: 0;
  top: 0;
  ${(props) =>
    props.isMobile &&
    css`
      max-width: 100%;
    `};
`;

const SocialIcon = styled(Icon)`
  margin-left: 1rem;
`;

const fadeIn = keyframes`
  from {transform: translateY(-700px); opacity: 0;}
  to { transform: translateY(0); opacity: 1;}
`;
const fadeOut = keyframes`
  from {transform: translateY(0); opacity: 1; }
  to { transform: translateY(-700px); opacity: 0; }
`;

export const MenuWrapper = styled.div<IOpen>`
  position: relative;

  ${({ open }) =>
    open
      ? css`
          display: block;
          animation: ${fadeIn} 0.5s forwards;
        `
      : css`
          display: block;
          animation: ${fadeOut} 0.5s forwards;
        `}
`;

const Menu: React.FC<IHeaderNavigation> = ({
  navLinks,
  onClose,
  socialIcons,
  open,
  onMenuItemClicked
}) => {
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <Overlay open={open} onClick={onClose}>
      <MenuWrapper open={open}>
        <MenuBackground isMobile={IS_MOBILE} src={menuBg} />
        <MenuContentContainer isMobile={IS_MOBILE}>
          <MenuInner>
            <Icon
              width={IS_MOBILE ? 16 : 24}
              onClick={onClose}
              src={CloseIcon}
              alt="stäng menu"
            />
            <Spacer spacing={IS_MOBILE ? 1 : 5} />
            {navLinks.map((item) => (
              <React.Fragment key={item.title}>
                <MenuLinkItem
                  onClick={() => onMenuItemClicked(item.to)}
                  color="primary"
                >
                  {item.title}
                  <MenuLinkIcon width={IS_MOBILE ? 15 : 30} src={ArrowIcon} />
                </MenuLinkItem>
                <Spacer />
              </React.Fragment>
            ))}
            {!IS_MOBILE && <Spacer spacing={2} />}
            {socialIcons.map((item) => (
              <a key={item.to} href={item.to} target="__blank">
                <SocialIcon
                  onClick={onClose}
                  width={IS_MOBILE ? 30 : 40}
                  src={item.icon}
                />
              </a>
            ))}
          </MenuInner>
        </MenuContentContainer>
      </MenuWrapper>
    </Overlay>
  );
};

export default Menu;
