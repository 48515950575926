import React from 'react';
import * as Styled from './style';
import Spacer from '../../atoms/spacer';
import Divider from '../../atoms/divider';
import Typography from '../../atoms/typography';
import NavigationBar from '../../molecules/navigationBar';
import { Col, Visible, Row } from 'react-grid-system';
import useScreenClassHelper from '../../../hooks/useScreenClassHelper';
import { INavLinks, ISocialImageLink } from '../../atoms/interfaces';
import Icon from '../../atoms/icon';
import Container from '../../atoms/container';
import BankIDLogo from '../../../images/bankid_logo_white.svg';
import SwedishEID from '../../../images/svensk_eid.png';

interface IFooter {
  navLinks: INavLinks[];
  socialImages: ISocialImageLink[];
  image: string;
}

const Footer: React.FC<IFooter> = ({ image, socialImages, navLinks }) => {
  const handleClick = () => {};
  const { IS_MOBILE } = useScreenClassHelper();

  return (
    <Container
      fluid
      backgroundColor="secondary"
      component="footer"
      style={{
        paddingTop: IS_MOBILE ? '1rem' : '3.5rem',
        paddingBottom: '1rem'
      }}
    >
      <Spacer spacing={2} />
      <Row align="center" justify="between">
        <Col xs={IS_MOBILE ? 12 : undefined}>
          <Styled.ImageLogo
            src={image}
            alt="logga"
            style={IS_MOBILE ? { margin: 'auto', display: 'block' } : {}}
          />
          {IS_MOBILE && <Spacer spacing={3} />}
        </Col>
        <Col xs={12} md="content">
          <Row align="center" justify="center">
            <Col xs={12} md="content">
              <Styled.PartnerLogo
                src="https://design.swedbankpay.com/v/5.0.2/img/swedbankpay-logo-h-neg.svg"
                alt="Swedbank Pay vertical logo"
                className="logotype-vertical logotype-lg"
                height="40"
              />
              {IS_MOBILE && <Spacer />}
            </Col>
            <Col xs={12} md="content">
              {IS_MOBILE && <Spacer />}
              <Styled.PartnerLogo height="40" src={SwedishEID} />
              {IS_MOBILE && <Spacer />}
            </Col>
            <Col xs={12} md="content">
              <Styled.PartnerLogo height="70" src={BankIDLogo} />
            </Col>
          </Row>
        </Col>
        <Col>
          {IS_MOBILE && <Spacer spacing={3} />}
          <Row align="center" justify={IS_MOBILE ? 'start' : 'end'}>
            {socialImages.map((item) => (
              <Col key={item.href} xs={IS_MOBILE ? 12 : 'content'}>
                <a href={item.href} target="__blank">
                  <Icon
                    style={
                      IS_MOBILE ? { margin: 'auto', display: 'block' } : {}
                    }
                    width={60}
                    onClick={handleClick}
                    src={item.src}
                    alt="social media"
                  />
                </a>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Spacer spacing={2} />
      <Divider />
      <Visible md lg xl xxl>
        <Spacer />
        <Row align="center">
          <Col>
            <Typography color="offWhite">
              ©2021 panBox. All Rights Reserved
            </Typography>
          </Col>
          <Col xs={12} md="content">
            <NavigationBar navLinks={navLinks} />
          </Col>
        </Row>
      </Visible>
      <Visible sm xs>
        <Row align="center" justify="center">
          <Col>
            <NavigationBar navLinks={navLinks} />
          </Col>
          <Spacer spacing={2} />
          <Col xs={12}>
            <Divider />
          </Col>
          <Spacer spacing={2} />
          <Col>
            <Typography align="center" color="offWhite">
              ©2021 panBox. All Rights Reserved
            </Typography>
          </Col>
        </Row>
      </Visible>
    </Container>
  );
};

export default Footer;
