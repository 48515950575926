// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-allmanna-villkor-tsx": () => import("./../../../src/pages/allmanna-villkor.tsx" /* webpackChunkName: "component---src-pages-allmanna-villkor-tsx" */),
  "component---src-pages-bestall-tsx": () => import("./../../../src/pages/bestall.tsx" /* webpackChunkName: "component---src-pages-bestall-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-coronaskydd-coronakit-tsx": () => import("./../../../src/pages/coronaskydd-coronakit.tsx" /* webpackChunkName: "component---src-pages-coronaskydd-coronakit-tsx" */),
  "component---src-pages-dataskyddspolicy-tsx": () => import("./../../../src/pages/dataskyddspolicy.tsx" /* webpackChunkName: "component---src-pages-dataskyddspolicy-tsx" */),
  "component---src-pages-fel-tsx": () => import("./../../../src/pages/fel.tsx" /* webpackChunkName: "component---src-pages-fel-tsx" */),
  "component---src-pages-foretagsansokan-tsx": () => import("./../../../src/pages/foretagsansokan.tsx" /* webpackChunkName: "component---src-pages-foretagsansokan-tsx" */),
  "component---src-pages-hem-2-tsx": () => import("./../../../src/pages/hem2.tsx" /* webpackChunkName: "component---src-pages-hem-2-tsx" */),
  "component---src-pages-hem-3-tsx": () => import("./../../../src/pages/hem3.tsx" /* webpackChunkName: "component---src-pages-hem-3-tsx" */),
  "component---src-pages-hidden-forms-tsx": () => import("./../../../src/pages/hiddenForms.tsx" /* webpackChunkName: "component---src-pages-hidden-forms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-klar-tsx": () => import("./../../../src/pages/klar.tsx" /* webpackChunkName: "component---src-pages-klar-tsx" */),
  "component---src-pages-kontakta-oss-tsx": () => import("./../../../src/pages/kontakta-oss.tsx" /* webpackChunkName: "component---src-pages-kontakta-oss-tsx" */),
  "component---src-pages-logga-in-tsx": () => import("./../../../src/pages/logga-in.tsx" /* webpackChunkName: "component---src-pages-logga-in-tsx" */),
  "component---src-pages-mina-sidor-tsx": () => import("./../../../src/pages/mina-sidor.tsx" /* webpackChunkName: "component---src-pages-mina-sidor-tsx" */),
  "component---src-pages-om-panbox-tsx": () => import("./../../../src/pages/om-panbox.tsx" /* webpackChunkName: "component---src-pages-om-panbox-tsx" */),
  "component---src-pages-produkter-tsx": () => import("./../../../src/pages/produkter.tsx" /* webpackChunkName: "component---src-pages-produkter-tsx" */),
  "component---src-pages-valkommen-tsx": () => import("./../../../src/pages/valkommen.tsx" /* webpackChunkName: "component---src-pages-valkommen-tsx" */),
  "component---src-pages-verifiera-tsx": () => import("./../../../src/pages/verifiera.tsx" /* webpackChunkName: "component---src-pages-verifiera-tsx" */)
}

