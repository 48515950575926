import React from 'react';
import { PromoCodeRes } from '../../models/payment';

export interface IPaymentContext {
  showPaymentSection: boolean;
  updatePanboxAmount: (amount: number) => void;
  panboxAmount: number;
  productCost: number;
  newSlots: number;
  subscriptionCost: number;
  totalCost: number;
  initialisePayment: () => Promise<void>;
  handleAddPromoCode: (promoCode: string) => Promise<void>;
  promoCodeData?: PromoCodeRes;
  resetData: () => void;
}

export default React.createContext<IPaymentContext>({
  showPaymentSection: false,
  panboxAmount: 1,
  updatePanboxAmount: () => undefined,
  productCost: 1,
  newSlots: 0,
  subscriptionCost: 0,
  totalCost: 0,
  initialisePayment: async () => undefined,
  handleAddPromoCode: async () => undefined,
  promoCodeData: undefined,
  resetData: () => undefined
});
