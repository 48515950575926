import React, { useState } from 'react';
import Snackbar from '../../components/atoms/snackbar';
import { SNACKBAR_DURATION } from '../../constants';
import SnackbarContext from './context';
export { SnackbarContext };

const SnackbarProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState('');
  const [duration, setDuration] = useState(SNACKBAR_DURATION);

  const handleMessage = (message: string, msgDuration?: number) => {
    setMessage(message);
    if (msgDuration) {
      setDuration(msgDuration);
    }
    setTimeout(() => {
      setMessage('');
    }, msgDuration ?? SNACKBAR_DURATION);
  };

  const value = {
    handleMessage
  };

  return (
    <SnackbarContext.Provider value={value}>
      <Snackbar open={message !== ''} message={message} duration={duration} />
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
