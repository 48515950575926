import React, { useEffect } from 'react';
import { useMemo, useState } from 'react';
import { getPromoCodeValue } from '../../api';
import useFirebaseContext from '../../hooks/useFirebaseContext';
import usePayment from '../../hooks/usePayment';
import useSnackbar from '../../hooks/useSnackbar';
import { PromoCodeRes } from '../../models/payment';
import { getDefaultTotalPrice } from '../../services/prices';
import { PAYMENT_TYPE, savePaymentType } from '../../utils/localStorage';
import PaymentContext from './context';

export { PaymentContext };

const PaymentContextProvider: React.FC = ({ children }) => {
  const { storageAmount, productAmount } = useFirebaseContext();
  const { initiatePayment, startPayment } = usePayment();
  const { handleMessage } = useSnackbar();

  const [showPaymentSection, setShowPaymentSection] = useState(false);
  const [amount, updateAmount] = useState(1);
  const [promoCodeData, setPromoCodeData] = useState<
    PromoCodeRes | undefined
  >();

  const { totalCost, subscriptionCost, productCost, newSlots, totalSaleCost } =
    useMemo(
      () =>
        getDefaultTotalPrice({
          productAmount,
          orderAmount: amount,
          storageAmount,
          promo: promoCodeData
        }),
      [storageAmount, productAmount, amount, promoCodeData]
    );

  const initialisePayment = async () => {
    savePaymentType(
      newSlots > 0
        ? PAYMENT_TYPE.DEFAULT_ORDER
        : PAYMENT_TYPE.DEFAULT_ORDER_NO_STORAGE
    );

    const res = await initiatePayment({
      amount: totalSaleCost * 100,
      numberOfPanBoxes: amount
    });
    setShowPaymentSection(res);
    if (!res) {
      handleMessage(
        'Något gick fel, vänligen försök igen eller kontakta oss om problemet kvarstår'
      );
    }
  };

  const handleAddPromoCode = async (promoCode: string) => {
    const res = await getPromoCodeValue(promoCode);
    if (!res) {
      handleMessage(
        'Tyvärr, vi hittade inte den angivna kampanjkoden. Observera att användningen av stora och små bokstäver måste stämma överens med originalkoden.',
        8000
      );
    }
    setPromoCodeData(res);
  };

  const resetData = () => {
    setShowPaymentSection(false);
    updateAmount(1);
    setPromoCodeData(undefined);
  };

  useEffect(() => {
    if (showPaymentSection) {
      startPayment();
    }
  }, [showPaymentSection]);

  const value = {
    updatePanboxAmount: updateAmount,
    panboxAmount: amount,
    productCost,
    newSlots,
    subscriptionCost,
    totalCost,
    initialisePayment,
    showPaymentSection,
    handleAddPromoCode,
    promoCodeData,
    resetData
  };

  return (
    <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
  );
};

export default PaymentContextProvider;
