import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import arrowUpWhite from '../../images/icons/arrow-up-white.png';
import theme from '../../styles/theme';

interface IScrollToTop {
  visible: boolean;
}

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1;}
`;

const fadeOutAnimation = keyframes`
  from {opacity: 1; }
  to  {opacity: 0; display: none;}
`;

const FadeInButton = styled.div<IScrollToTop>`
  position: fixed;
  bottom: 80px;
  right: 0;
  z-index: 999;
  border-radius: 5px 0 0 5px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.textPrimary}};
  ${({ visible }) =>
    visible
      ? css`
          display: flex;
          animation: ${fadeInAnimation} 0.5s ease-in forwards;
        `
      : css`
          animation: ${fadeOutAnimation} 0.5s ease-in forwards;
        `}
`;

const ScrollToTop: React.FC = () => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleVisible);
    return function unMount() {
      window.removeEventListener('scroll', toggleVisible);
    };
  }, [visible]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <FadeInButton visible={visible} onClick={scrollToTop}>
        <img src={arrowUpWhite} />
      </FadeInButton>
    </>
  );
};

export default ScrollToTop;
