import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';
import { ITypography, VariantType } from './interfaces';

const StyledTypography = css<ITypography>`
  color: ${(props) =>
    props.color
      ? props.theme.colors[props.color] ?? props.theme.colors.textPrimary
      : props.theme.colors.textPrimary};
  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}
  width: 100%;
`;

const StyledHeader = styled.h1<ITypography>`
  ${StyledTypography};
  font-size: 3rem;
`;

const StyledH2 = styled.h2<ITypography>`
  ${StyledTypography};
  font-size: 2rem;
`;

const StyledH3 = styled.h3<ITypography>`
  ${StyledTypography};
  font-size: 1.5rem;
`;

const StyledH4 = styled.h4<ITypography>`
  ${StyledTypography};
  font-size: 1.1rem;
`;

const StyledBody = styled.p<ITypography>`
  ${StyledTypography};
  font-size: 1rem;
`;

const StyledLink = styled(Link)<ITypography>`
  ${StyledTypography};
  font-size: 1rem;
  text-decoration: none;
`;

const StyledExternalLink = styled.a<ITypography>`
  ${StyledTypography};
  font-size: 1rem;
  text-decoration: none;
`;

const StyledInputError = styled.p<ITypography>`
  ${StyledTypography};
  font-size: 1rem;
  margin-top: 4px;
  color: ${(props) => props.theme.colors.error};
`;

const StyledLabel = styled.label<ITypography>`
  ${StyledTypography};
`;

const StyledSpan = styled.span<ITypography>`
  ${StyledTypography};
  display: inline;
  width: auto;
`;

const getComponent = (variant: VariantType): React.FC => {
  switch (variant) {
    case 'header':
      return StyledHeader;
    case 'body':
      return StyledBody;
    case 'link':
      return StyledLink;
    case 'external-link':
      return StyledExternalLink;
    case 'h4':
      return StyledH4;
    case 'h3':
      return StyledH3;
    case 'h2':
      return StyledH2;
    case 'error':
      return StyledInputError;
    case 'label':
      return StyledLabel;
    case 'span':
      return StyledSpan;
    default:
      return StyledBody;
  }
};

const Typography: React.FC<ITypography> = ({ variant, ...props }) => {
  const Component = getComponent(variant);
  return <Component {...props} />;
};

export default Typography;
