const theme = {
  colors: {
    pageBackground: '#EEEEEE',
    primary: '#00AAAA',
    primaryLight: '#BBDDDD',
    secondary: '#EE4F4F',
    textPrimary: '#343434',
    placeholderPrimary: '#8E8E93',
    textSecondary: '#FFFFFF',
    formBackground: '#EEEEEE',
    textInputBackground: '#FFFFFF',
    error: '#EE4F4F',
    cardBackground: '#FFFFFF',
    modalBackground: '#FFFFFF',
    offWhite: '#f2f3f4',
    drawerBackground: '#575757',
    sky: '#91C5E0'
  }
};

export default theme;
