import isBrowser from './isBrowser';

const PAYMENT_ID_KEY = 'pay_id';
const PAYMENT_TYPE_KEY = 'pay_type';
const SESSION_ID_KEY = 'sess_id';
const ORDER_AMOUNT = 'ord_am';
const PAYMENT_NUM_KEY = 'pay_num';

export enum PAYMENT_TYPE {
  END_SUBSCRIPTION = 0,
  DEFAULT_ORDER = 1,
  DEFAULT_ORDER_NO_STORAGE = 2,
  ORDER_HOME = 3,
  ORDER_HOME_RESTOCK = 4,
  RESERVE = 5,
  SUBSCRIPTION = 99
}

export const savePaymentId = (paymentId: string) => {
  if (isBrowser()) {
    localStorage.setItem(PAYMENT_ID_KEY, paymentId);
  }
};

export const getPaymentId = (): string | null =>
  isBrowser() ? localStorage.getItem(PAYMENT_ID_KEY) : null;

export const removePaymentId = () => {
  if (isBrowser()) {
    localStorage.removeItem(PAYMENT_ID_KEY);
  }
};

export const savePaymentType = (type: PAYMENT_TYPE) => {
  if (isBrowser()) {
    localStorage.setItem(PAYMENT_TYPE_KEY, type.toString());
  }
};

export const getPaymentType = (): number | undefined => {
  if (isBrowser()) {
    const data = localStorage.getItem(PAYMENT_TYPE_KEY);
    return data ? parseInt(data) : undefined;
  }
};

export const removePaymentType = () => {
  if (isBrowser()) {
    localStorage.removeItem(PAYMENT_TYPE_KEY);
  }
};

export const savePanboxOrderAmount = (amount: number) => {
  if (isBrowser()) {
    localStorage.setItem(ORDER_AMOUNT, amount.toString());
  }
};

export const getPanboxOrderAmount = (): number | null => {
  if (!isBrowser()) return null;
  const stringValue = localStorage.getItem(ORDER_AMOUNT);
  if (!stringValue) return null;
  return parseInt(stringValue);
};

export const removePanboxOrderAmount = () => {
  if (isBrowser()) {
    localStorage.removeItem(ORDER_AMOUNT);
  }
};

export const saveSessionId = (id: string) => {
  if (isBrowser()) {
    localStorage.setItem(SESSION_ID_KEY, id);
  }
};

export const getSessionId = (): string | null =>
  isBrowser() ? localStorage.getItem(SESSION_ID_KEY) : null;

export const removeSessionId = () => {
  if (isBrowser()) {
    localStorage.removeItem(SESSION_ID_KEY);
  }
};

export const savePaymentNumber = (paymentNumber: string) => {
  if (isBrowser()) {
    localStorage.setItem(PAYMENT_NUM_KEY, paymentNumber);
  }
};

export const getPaymentNumber = (): string | null =>
  isBrowser() ? localStorage.getItem(PAYMENT_NUM_KEY) : null;

export const removePaymentNumber = () => {
  if (isBrowser()) {
    localStorage.removeItem(PAYMENT_NUM_KEY);
  }
};

export const resetPaymentDetails = () => {
  removePaymentId();
  removePaymentNumber();
  removePaymentType();
  removePanboxOrderAmount();
};
