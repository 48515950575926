import styled, { css } from 'styled-components';

interface IMobile {
  isMobile: boolean;
}

export const Header = styled.header<IMobile>`
  position: absolute;
  background-color: ${(props) => props.theme.colors.transparent};
  top: 0;
  left: 0;
  width: 100%;
  z-index: 800;
  ${(props) =>
    props.isMobile &&
    css`
      background-color: ${(props) => props.theme.colors.offWhite};
      box-shadow: 0 5px 10px rgb(0 0 0 / 50%);
    `};
`;

export const Shadow = styled.div`
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
  mix-blend-mode: multiply;
  height: 300px;
  width: 100%;
  pointer-events: none;
`;

export const HeaderInner = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const ImageLogo = styled.img`
  object-fit: contain;
  max-width: 190px;
`;

export const ContainerLogo = styled.div`
  width: 150px;
  cursor: pointer;
`;

export const Menu = styled.div`
  display: flex;
  padding-top: 0.25rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  :hover {
    opacity: 80%;
  }
`;
