import React from 'react';

interface IHamburgerMenu {
  color?: string;
}

const HamburgerMenu: React.FC<IHamburgerMenu> = ({ color = 'white' }) => (
  <svg viewBox="0 0 100 80" width="40" height="20">
    <rect fill={color} width="100" height="10"></rect>
    <rect fill={color} y="30" width="100" height="10"></rect>
    <rect fill={color} y="60" width="100" height="10"></rect>
  </svg>
);

export default HamburgerMenu;
